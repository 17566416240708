import React from "react";


const ProgressBar = ({ children, ...restProps }) => {
  return (
        <div className="progress mt-3">
            <div className="progress-bar bg-info" role="progressbar" style={{width: restProps.value+"%"}}  aria-valuenow={restProps.value} aria-valuemin="0" aria-valuemax="100">
                {(restProps.value > 0)?children:""}
            </div>
        </div>
  );
}

export default ProgressBar;