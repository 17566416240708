import React from "react";


const Form = ({ children, ...restProps }) => {
  return (
    <form className={restProps.className?restProps.className:"form-base"} {...restProps}>
      {children}
    </form>
  );
}

export default Form;

