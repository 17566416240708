import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../Content/Header/Header';
import NavBar from '../Content/NavBar/NavBar';

const DashboardLayout = (props) => (
    <>
    <Header />
    <div className="container-dashboard">
    <NavBar />
      <div className="content-page">
        <Outlet /> 
      </div>
    </div>
    </>
  );
  
export default DashboardLayout;