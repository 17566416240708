import { useState, useRef, useEffect } from "react";
import { fetchServerGET, fetchServerPOST } from "../helpers/fetchServer";

import Loader from "../components/Content/Loader/Loader";

import { dateMysql } from "../helpers/date";

import { getDatasForm, isErrorField } from "../helpers/form";

import Printed from "../components/Content/Serials/Printed";

const AssociateSerialDistributorPage = () => {

    //Reference inpàut
    const inputRef = useRef(null);

    //Data
    const [idDistributor, setIdDistributor] = useState(false);
    const [distributor, setDistributor] = useState(false);
    const [serial, setSerial] = useState(false);

    const [error, setError] = useState("");
    const [loader, setLoader] = useState(false);

    const [dataSerialAssociate, setDataSerialAssociate] = useState([]);
  

    function handleFormSubmit(event) {

        event.preventDefault();

        //Get Data Forms
        const form = getDatasForm(event.target);

        setLoader(true);

        if(form.datas.value){

          //Change distributor
          if(form.datas.value.indexOf('distributor§') !== -1){
            
            setDistributor(false);
            setIdDistributor(form.datas.value.replace('distributor§', ''));
            setLoader(false);
          }else{
            if(distributor == false){
              alert("Aucun distributeur sélectionné");
            }else{
              fetchServerPOST("/distributor/associateQrcode/",{distributor:idDistributor, serial:form.datas.value} ,(response)=>{
                
                if(response && response.success === true){
                  if(Array.isArray(dataSerialAssociate)){
                    dataSerialAssociate.push({number:form.datas.value, distributor:idDistributor, date:dateMysql(), "qrcode":1})
                    setDataSerialAssociate(dataSerialAssociate);
                  }
                }else{
                  alert(response.error);
                }

                
                setLoader(false);
              })

             
            }
            
          }

          inputRef.current.value = '';
          inputRef.current.focus();
        }
        

        /*if(distributor && !isNaN(distributor)){
          setLoader(true);
          fetchServerPOST("/distributor/associateQrcode/",{distributor:distributor, serial:serial} ,(response)=>{
            setLoader(false);
            if(response && response.success === true){
              if(Array.isArray(dataSerialAssociate)){
                dataSerialAssociate.unshift({number:serial, distributor:distributor, date:dateMysql(), "qrcode":1})
              }
            }
          })
        }*/
        
    }

    function handleDistributor(event){
        event.preventDefault();

        //Get Data Forms
        const form = getDatasForm(event.target);

        

        if(form.datas.distributor){
          setLoader(false);
          setDistributor(form.datas.distributor);
        }else{
          setLoader(false);
        }
    }

    useEffect(() => {
      if(idDistributor && !isNaN(idDistributor)){
        setLoader(true);
        fetchServerGET("/distributor/get/"+idDistributor, (response)=>{
            if(response.success){
              
              setDistributor(response.data.distributor)
              setDataSerialAssociate(response.data.qrcodeList);
            }else{
              
            }
            setLoader(false);
            
        });
        inputRef.current.value = '';
        inputRef.current.focus();
      }
    },[idDistributor]);


      return (
        <>
         <div>
           
            <div className="row">
              {loader && <Loader />}
              
              <div className="col-6">
                <form className="form-material" onSubmit={handleFormSubmit} style={{opacity:1}}>
                  <input type="text" name="value" ref={inputRef} autoFocus onChange={(e)=>{}} className="form-control"  />
                </form>
              </div>
            </div>

            {distributor && 
                <>
                  <div className="row mt-3">
                    <strong><u>Distributeur :</u></strong>&nbsp; {distributor.name}
                  </div>
                  <div className="row mt-3">
                    Liste des derniers numéros de séries associés :<br />
                    <div>{dataSerialAssociate && dataSerialAssociate.map( (row,index) => <Printed key={index} serial={row.number} printedAt={row.date} />)}</div>
                  </div>
                </>
              }
           
              
             
           
         </div>
        </>
      );
}

export default AssociateSerialDistributorPage;