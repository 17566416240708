import { useState, useRef, useEffect } from "react";
import { fetchServerGET, fetchServerPOST } from "../helpers/fetchServer";

import QrcodePrint from "../components/Content/Print/QrcodePrint";
import {useReactToPrint} from "react-to-print"
import { isValidSerial, replaceForValidSerial } from "../helpers/form";
import Loader from "../components/Content/Loader/Loader";
import Printed from "../components/Content/Serials/Printed";

const QrcodePage = () => {
    const componentRef = useRef(null);
    const inputRef = useRef(null);

    const [qrcode, setQrcode] = useState("");
    const [emaildirectors, setEmaildirectors] = useState(0);
    const [error, setError] = useState("");
    const [loader, setLoader] = useState(false);


    const [dataSerialPrinted, setDataSerialPrinted] = useState(false);
    const [reloadData, setReloadData] = useState(0);

    function handleSubmit(event) {
        event.preventDefault();
        setLoader(true);
        fetchServerGET("/serial/printedQrcode/"+qrcode, (response)=>{
          setLoader(false);
          if(response && response.data.printed){
              if(window.confirm("Ce numéro de série a déjà été imprimé. Voulez vous l'imprimer à nouveau?")){
                if(qrcode.startsWith('S') || qrcode.startsWith('FS')){
                  if(window.confirm("Attention !!!!!\n\nCe numéro semble correspondre au Physiosculpt, êtes-vous sûr de vouloir le re-imprimer?")){
                    handlePrint();
                  }
                }else{
                  handlePrint();
                }
              }
          }else{
            handlePrint();
          }

          
        })
        
    }

    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
      onAfterPrint : ()=>{
        inputRef.current.focus();
        fetchServerPOST("/serial/qrcode",{"serial":qrcode, "emaildirectors":emaildirectors},()=>{
          setReloadData(reloadData+1);
        }, (err)=>{console.log("err")});
      }
      
    });

    useEffect(() => {
      setLoader(true);
      fetchServerGET("/serial/listPrintedToday", (response)=>{
        setLoader(false);
        
        setDataSerialPrinted(response.data);
      })


    },[reloadData]);



      return (
        <>
         <div>
           
            <div className="row">
              {loader && <Loader />}
              <div className="col-6">
                <form className="form-material" onSubmit={handleSubmit}>
                  <input type="text" ref={inputRef} autoFocus className="form-control" onChange={({ target }) => setQrcode(replaceForValidSerial(target.value))} value={qrcode} />
                  <br />
                  <label>
                  <input type="checkbox"  className="" onChange={({ target }) => setEmaildirectors(target.checked?1:0)} value={emaildirectors} /> Suivi direction (ex: Dorsia) </label>
                  <br />
                  <button type="submit" className="btn btn-primary btn-md btn-block waves-effect text-center m-b-20">Imprimer</button>
                </form>
              </div>
              <div className="col-6" id="print-content" ref={componentRef}>

                  <QrcodePrint serial={qrcode} />
              </div>
            </div>

            <div className="row mt-3">
              
              {dataSerialPrinted && dataSerialPrinted.map( (row,index) => <Printed key={index} serial={row.number} printedAt={row.printed_at} />)}
            </div>
         </div>
        </>
      );
}

export default QrcodePage;