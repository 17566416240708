import React, { useState } from "react";
import process from "process";
import { useTranslation } from "react-i18next";
import { fetchServerGET, fetchFile } from "../../../../helpers/fetchServer";
import getType from "../../../../helpers/file";


const MediaTicket = ({ filename }) => {

    const { t } = useTranslation();
    const type = getType(filename);

    const urlServer = process.env.REACT_APP_SERVER_URL;
    const [urlFile, setUrlFile] = useState(false);

    

    

      /*!urlFile && fetchFile("/ticket/file/"+filename, (data)=>{
            
          setUrlFile((window.URL || window.webkitURL).createObjectURL( new Blob([data.data]) ))
        
      });*/

  return (
    <>
        {type && 
        
           <div>
               {type=="image" && <img width="100%" src={urlServer+"/ticket/file/"+filename} />}
               {type=="video" && <video width="765" controls> <source  type="video/mp4" src={urlServer+"/ticket/file/"+filename} ></source></video>}
               
           </div>
        }
      </>
  );
}

export default MediaTicket;