import React from "react";


const TextArea = ({ children, ...restProps }) => {
  return (
    <textarea className={restProps.className?restProps.className:"form-textarea"} {...restProps}>
      {children}
    </textarea>
  );
}

export default TextArea;