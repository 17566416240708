import React, { useRef } from "react";
import { useState } from "react";


const Input = ({ children, ...restProps }) => {

  const [file, setFile] = useState(null);

  const handleChange = (event) => {
    setFile({src:URL.createObjectURL(event.target.files[0]), type:event.target.files[0].type})
  }

  return (
    <div className="mb-3">
      <input type="file" className={restProps.className?restProps.className:"form-input"} {...restProps} onChange={handleChange} />
      {(file && file.type.indexOf('image')==0)  &&<img width={200} src={file.src}/>}
      {(file && file.type.indexOf('video')==0)  && <video controls width="250"><source src={file.src} type={file.type} /></video>}
    </div>
  );
}

export default Input;
