
export default function getType(filename){
    const extension = /[^.]+$/.exec(filename);

    if(extension){
        if(extension.toString().toLowerCase() == "jpg" || extension.toString().toLowerCase() == "jpeg" || extension.toString().toLowerCase() == "png")
            return "image";
        else
            return "video";
    }

    return false;
}