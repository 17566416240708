import { useState } from "react";


const HomePage = () => {
   

      return (
        <>
          Home Page
        </>
      );
}

export default HomePage;