import React, { useState } from 'react';
import {useNavigate} from "react-router-dom";

import { fetchServerGET } from '../../../helpers/fetchServer';

const UserMenu = (props) => {

    const history = useNavigate();
    const user = JSON.parse(sessionStorage.getItem("user"));

    const [displayUserMenu, setDisplayUserMenu] = useState("false");

    const logout = () => {
        sessionStorage.clear();

        //Logout Server
        fetchServerGET("/user/logout");
        
        //Redirect to login
        history("/login");
    }
 
    return   (
        <>
            <ul className='nav-right'>
                <li className='user-profile' onClick={() =>setDisplayUserMenu(!displayUserMenu)}>
                    <span className='user-info' >{user.firstname} {user.lastname}</span>

                    {/* User menu */}
                    <div className={"bg-menu-close"+ ((displayUserMenu)?' none':'')} onClick={() =>{setDisplayUserMenu(!displayUserMenu);}}></div>
                    <ul className={"user-menu"+ ((displayUserMenu)?' none':'')}>
                        <li>
                            Settings
                        </li>
                        <li onClick={logout}>
                            Deconnexion
                        </li>
                    </ul>
                    
                </li>
            </ul>
        </>
    )
}

export default UserMenu;