
export const dateSQLToLocaleString = (date) => {
   
    let dateString = "";

    if(date){
        dateString = new Date(date).toLocaleString();
    }

    return dateString;
}

export const dateMysql = (dateString = null) =>  {

    let date = null;
    if(dateString=== null){
        date = new Date();
    }else{
        date = new Date(dateString);
    }

    if(date){
        date = date.getUTCFullYear() + '-' +
        ('00' + (date.getUTCMonth()+1)).slice(-2) + '-' +
        ('00' + date.getUTCDate()).slice(-2) + ' ' + 
        ('00' + date.getUTCHours()).slice(-2) + ':' + 
        ('00' + date.getUTCMinutes()).slice(-2) + ':' + 
        ('00' + date.getUTCSeconds()).slice(-2);
    }

    return date;
}