import { useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Captcha from "../components/Content/Form/Captcha";
import Form from "../components/Content/Form/Form";
import Button from "../components/Content/Form/Button";
import Label from "../components/Content/Form/Label";
import Input from "../components/Content/Form/Input";
import TextArea from "../components/Content/Form/TextArea";
import Title1 from "../components/Content/Form/Title1";
import File from "../components/Content/Form/File";
import Text from "../components/Content/Form/Text";
import { getDatasForm, isErrorField } from "../helpers/form";
import { fetchServerPOST, fetchDataAndFilePOST } from "../helpers/fetchServer";
import Modal from "../components/Content/Modal/Modal";
import Lang from "../components/Content/Lang/Lang";
import ProgressBar from "../components/Content/ProgressBar/ProgressBar";

const TicketPage = () => {
  const { t } = useTranslation();

  //Get Serial Number 
  const params = useParams();
  const serialNumber = params.serial;
  let atributesSerial = {};

  if (serialNumber) {
    atributesSerial = {
      value: serialNumber,
      readOnly: true
    }
  }

  const [errors, setErrors] = useState(false);
  const [modal, setModal] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  let reloadRef = useRef(0);

  //Submit Form
  function handleSubmit(event) {
    event.preventDefault();

    //Remove Modal 
    if (modal)
      setModal(false);

    //Get Data Forms
    const form = getDatasForm(event.target);

    //For not errors
    if (form.errors.length == 0) {
      fetchDataAndFilePOST("/ticket/add-public", form.formdatas, (server) => {
        if (server.success) {

          setIsSubmit(true);
          setUploadProgress(0);
        } else {

          console.log(server)
          setUploadProgress(0);

          if (server.error) {
            setErrors(server.error);
            setModal(true);
          }

        }
      }, (err) => {
        setUploadProgress(0);
        if (err.response.data.error) {
          setErrors(err.response.data.error);
          setModal(true);
        }
      }, (data) => { setUploadProgress(Math.round(data.loaded / data.total * 100)) })
    } else {
      setErrors(form.errors);
      setModal(true);
    }
  }


  return (
    <div className="row" style={{ margin: 20 }}>
      <div className="col-12 col-md-10 col-lg-8 col-xl-6 col-xxl-4 m-auto bg-white" style={{ padding: 20 }}>

        {!isSubmit ? <div>
          <Title1 className="mb-4">{t('common.public_add_ticket_title')}</Title1>
          <Text style={{ color: "#999" }}>{t('common.public_add_ticket_text')}</Text>
          <Form onSubmit={handleSubmit} encType="multipart/form-data">

            <Label className="form-label">* {t('common.serial')}</Label>
            <Input type="text" {...atributesSerial} data-required name="serial" className={"form-control mb-3" + isErrorField(errors, "serial", " border-error", "") + (serialNumber ? " bg-light" : "")} />

            <Label className="form-label">{t('common.company')}</Label>
            <Input type="text" name="company" className={"form-control mb-3"} />

            <Label className="form-label">* {t('common.firstname')}</Label>
            <Input type="text" name="firstname" className={"form-control mb-3" + isErrorField(errors, "firstname", " border-error", "")} data-required />


            <Label className="form-label">* {t('common.lastname')}</Label>
            <Input type="text" name="lastname" className={"form-control mb-3" + isErrorField(errors, "lastname", " border-error", "")} data-required />

            <Label className="form-label">* {t('common.email')}</Label>
            <Input type="email" name="email" className={"form-control mb-3" + isErrorField(errors, "email", " border-error", "")} data-required />

            <Label className="form-label">{t('common.phone')}</Label>
            <Input type="number" name="phone" className="form-control mb-3" />

            <Label className="form-label">* {t('common.comment')}</Label>
            <TextArea rows="5" name="comment" className={"form-control mb-3" + isErrorField(errors, "comment", " border-error", "")} data-required />

            <Label className="form-label">{t('common.photo')}</Label>
            <File name="photo" className="form-control mb-3" accept="image/jpeg,image/png,video/*" />

            <Label className="form-label">* {t('common.captcha')}</Label>
            <Captcha />
            <Button className="btn btn-primary btn-block">{t('common.submit')}</Button>
          </Form></div> :
          <p>
            Your request has been forwarded to technical support.<br /><br />

            An email has been sent to you with your file number.
          </p>}
      </div>

      {modal &&
        <Modal setIsOpen={setModal} close={true} title="Error">

          <ul className="error">
            {(Array.isArray(errors) && errors.length) ? errors.map((error) =>
              <li >{t("common." + error.error).replace('%field%', t("common." + error.name))}</li>
            ) : <li>{errors}</li>}
          </ul>
        </Modal>
      }
      {uploadProgress &&
        <Modal setIsOpen={setModal} close={false} title={t("common.sending")}>
          {t("common.sending_request_technical_support")} <br />
          <ProgressBar value={uploadProgress} >
            {uploadProgress}  %
          </ProgressBar>
        </Modal>
      }
    </div>

  );
}

export default TicketPage;