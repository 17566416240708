import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "../../Modal/Modal";


const ButtonWithModal = ({ children, ticketId, itemsModal, modaltitle, typeModal, ...restProps }) => {

    const { t } = useTranslation();

    const [modal, setModal] = useState(false);
    

  return (
    <>
        <button className="action"  onClick={() => {setModal(true)}} {...restProps}>
            {children}
            
        </button>
        {modal && 
            <Modal setIsOpen={setModal} typeModal={typeModal} close={true} title={modaltitle}>
                {itemsModal }
            </Modal>
        }
      </>
  );
}

export default ButtonWithModal;