import { useRef, useState, useEffect } from "react";
import {BrowserRouter as Router, Routes, Route, useNavigate} from "react-router-dom";
import {acl} from './configs/acl';
import DashboardLayout from "./components/layout/DashboardLayout";
import {fetchServerGET} from "./helpers/fetchServer";
import  LoginPage  from "./pages/LoginPage";
import HomePage from "./pages/HomePage";
import QrcodePage from "./pages/QrcodePage";
import './assets/css/bootstrap.min.css';
import './assets/css/style.css';
import TicketPage from "./pages/TicketPage";
import TicketList from "./pages/Ticket/List/List";
import AssociateSerialDistributorPage from "./pages/AssociateSerialDistributorPage";

import process from "process";


const App = () => {

  const [role, setRole] = useState("guest");

  const location = window.location.pathname;  

  const checkAuth = useRef(0);

  useEffect(() => {

        if(process.env.REACT_APP_NODE_ENV !== "development"  &&  location != "/login" && location.indexOf("/add")!=0){

          setInterval(()=>{
              
              fetchServerGET("/user/isAuth", (response) => {
                if(!response.isAuth)
                  window.location.href="/login"
                
              })

          }, 30000);
        }
  
  },[role])

  const RouteList = () => {
    let sessionUser =sessionStorage.getItem("user");
    
    if(sessionUser) {
      sessionUser = JSON.parse(sessionUser);
      //setRole(sessionUser.role);
    }else{

        if(location != "/login" && location.indexOf("/add")!=0)
            window.location.href="/login"
    }

    
    return (
      <Routes>
        <Route exact path="/login"  element={ <LoginPage role={setRole} />} />
        <Route exact path="/add"  element={ <TicketPage />} />
        <Route exact path="/add/*">
            <Route path=":serial" element={<TicketPage />}/>
        </Route>
        <Route path="/" element={<DashboardLayout role={role} />}>
            <Route exact path="/" element={ <HomePage />} />
            <Route exact path="/print/qrcode" element={ <QrcodePage />} />
            <Route exact path="/tickets/list" element={ <TicketList />} />
            <Route exact path="/associate" element={ <AssociateSerialDistributorPage />} />
        </Route>
      </Routes>
    );
  }
  
  return (
    <Router>
        <RouteList />
    </Router>
  );
}

export default App;
