import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import getType from "../../../helpers/file";

import Delete from "../../../components/Content/Table/Button/Delete";
import DataTable from "../../../components/Content/Table/DataTable";
import Title from "../../../components/Content/Title/Title";
import ButtonWithModal from "../../../components/Content/Table/Button/ButtonWithModal";
import ActionsTicket from "../../../components/Content/Tickets/ActionsTicketTable";
import OneTicket from "../../../components/Content/Tickets/One/One";
import MediaTicket from "../../../components/Content/Tickets/Media/Media";

const TicketList = () => {


  const { t } = useTranslation();


  const thead = [
    {width:40, title:"#", key:"id"},
    {width:100, title:"Status", key:"status", keyItems:"status", items: (keyItems) => <label className={"label label-"+keyItems}>{keyItems}</label>},
    {width:100, title:"Dossier", key:"number"},
    {width:100, title:"Num série", key:"serial"},
    {width:false, title:"Commentaire", key:"comment"},
    {width:100, title:"Preuve?", key:"file", keyItems:"file", items: (keyItems)=>{
      const type = getType(keyItems);

      if(type){
        return  <ButtonWithModal ticketId={keyItems} key={"preuve-"+keyItems} typeModal="modal-lg" itemsModal={<MediaTicket filename={keyItems} />}  modaltitle={t("ticket.ticket_number",{id:keyItems})} >{t('ticket.'+type)}</ButtonWithModal>
      }
      return "";
    }},
    {width:100, title:"Action", key:"action", keyItems:"id", items: (keyItems) => {
      return <>
        <ButtonWithModal ticketId={keyItems} key={"action-"+keyItems} typeModal="modal-lg" itemsModal={<OneTicket idTicket={keyItems} />}  modaltitle={t("ticket.ticket_number",{id:keyItems})} >View</ButtonWithModal>
      </>
    }},
  ]



      
      
  return (
    <>
      <Title>{t('ticket.list')}</Title>
      <DataTable thead={thead} url="/ticket/list-table" width="100%" pagination={true} searchbar={true} changeRows={true} />
    </>
  );
}

export default TicketList;