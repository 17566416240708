import React from "react";

const Modal = ({setIsOpen, title, close, typeModal, children}) => {
  return (
    <>
      {/*<div className={styles.darkBG} onClick={() => setIsOpen(false)} />
      <div className={styles.centered}>
        <div className={styles.modal}>
          <div className={styles.modalHeader}>
            <h5 className={styles.heading}>Dialog</h5>
          </div>
          <button className={styles.closeBtn} onClick={() => setIsOpen(false)}>
            <RiCloseLine style={{ marginBottom: "-3px" }} />
          </button>
          <div className={styles.modalContent}>
            Are you sure you want to delete the item?
          </div>
          <div className={styles.modalActions}>
            <div className={styles.actionsContainer}>
              <button className={styles.deleteBtn} onClick={() => setIsOpen(false)}>
                Delete
              </button>
              <button
                className={styles.cancelBtn}
                onClick={() => setIsOpen(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
  </div>*/}

        <div className={"modal fade show"} tabIndex="-1" style={{display:"block"}}>
            <div className="bg-modal" onClick={() => (close)?setIsOpen(false):null}></div>
            <div className={"modal-dialog"+((typeModal)?" "+typeModal:"")} role="document">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">
                        {title}
                    </h5>
                    {close && <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setIsOpen(false)}><span aria-hidden="true">&times;</span></button>}
                </div>
                <div className="modal-body">
                    {children}
                </div>
                <div className="modal-footer">
                    {close && <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => setIsOpen(false)}>Close</button>}

                    
                </div>
                </div>
            </div>
        </div>
    </>
  );
};

export default Modal;