import React from 'react';
import QrCode from "react-qr-code";



const QrcodePrint = (props) => {


    const style = {
        border: "0px solid black",
        
        width: "2.8in",
        height: "1.1in"
    }

    const styleSpan = {
       fontSize: 12
    }

    const styleQr = {
        padding: 0,
        paddingTop: 13,
        paddingLeft: 5
     }

     const styletxt = {
        width: "1.6in",
        padding: 0,
        lineHeight:1,
        paddingLeft:2,
        paddingTop: 13,
     }


     const styleserialtitle = {
        fontSize: 11,
        marginBottom: 2
     }
     const styleserial = {
        fontSize: 8,
        whiteSpace: 'pre-wrap', 
        overflowWrap: 'break-word'
     }

     const styletechnicalsupport = {
        marginBottom: 10,
        
     }



    return (
        <>
            <div className='row' style={style}>
            
               <div className="col-4" style={styleQr} > <QrCode size={80}  value={"https://www.physts.com/add/"+props.serial} /></div>
               <div className="col-7" style={styletxt}> 
                    <div style={styletechnicalsupport}>
                        <span style={styleSpan}>Technical support</span> 
                    </div>
                    <div style={styleserialtitle}>
                        <strong>S/N</strong>
                    </div>
                    <div style={styleserial}>
                        <span style={styleSpan}>{props.serial}</span> 
                    </div>
               </div>
               
                
            </div>
        </>
    );
}

export default QrcodePrint;