import React from "react";


const Text = ({ children, ...restProps }) => {
  return (
    <p className={restProps.className?restProps.className:"form-text"} {...restProps}>
      {children}
    </p>
  );
}

export default Text;