export const acl = new Map();
acl.set('/', {navbarDashboard: true, group:"navigation",  title:"Home", allow: ["sav", "distrib", "admin"]});
acl.set('/tickets/list', {navbarDashboard: true, group:"navigation", title:"Liste des tickets", allow: ["sav", "distrib", "admin"]});
acl.set('/tickets/add-ticket', {navbarDashboard: false, group:"navigation", title:"Ajouter un ticket", allow: ["sav", "distrib", "admin"]});
acl.set('/print/qrCode', {navbarDashboard: true, group:"navigation", title:"QrCode", allow: ["sav", "admin"]});
acl.set('/associate', {navbarDashboard: true, group:"navigation", title:"Associate Distri/Serial", allow: ["sav", "admin"]});

//Add ticket guest
acl.set('/add-ticket', {navbarDashboard: false, group:"navigation", title:"Ajouter un ticket", allow: ["guest"]});

//User
acl.set('/users/list', {navbarDashboard: true, group:"admin", title:"Liste des utilisateurs", allow: ["admin"]});
acl.set('/users/add', {navbarDashboard: false, group:"admin", title:"Ajouter un utilisateur", allow: ["admin"]});

export const isAllowed = (url, role) => {
    let checkAcl = acl.get(url);

    if(checkAcl && checkAcl.allow && checkAcl.allow.find(element=>element==role))
        return true;

    return false;
}