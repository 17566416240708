import process from "process";
import axios from "axios";

export const fetchServerPOST = (url, params, successCallback, errorCallback, file=false) => {
   const sendUrl = process.env.REACT_APP_SERVER_URL+url;

   
   const headers =  { 
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    };


   const requestOptions = {
        
        method: "POST",
        headers : headers,
        body: JSON.stringify(params),
        credentials: "include",
    };

    fetch(sendUrl, requestOptions).then(response => response.json()).then(data => successCallback(data)).catch(error => {
        if(errorCallback)
            errorCallback(error);
        else
            console.log(error);
    });
   
}

export const fetchDataAndFilePOST = (url, params, successCallback, errorCallback, onUpload) => {
    const sendUrl = process.env.REACT_APP_SERVER_URL+url;

   
    const headers =  { 
      
    };

    if(onUpload)
        headers.onUploadProgress = (data) =>onUpload(data)

    axios.post(sendUrl, params, headers).then((data)=> successCallback(data.data)).catch(error => {
        if(errorCallback)
            errorCallback(error);
        else
            console.log(error);
    });
}

export const fetchServerGET = (url,  successCallback, errorCallback) => {
    const sendUrl = process.env.REACT_APP_SERVER_URL+url;

    const headers =  { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    };


    const requestOptions = {
        credentials: "include",
        headers : headers,
    };

 
    fetch(sendUrl, requestOptions).then((response) => response.json()).then((responseJson) => {return successCallback(responseJson)}).catch(error => {

         if(errorCallback)
             errorCallback(error);
         else
             throw(error);
     });
    
 }

 export const fetchFile = (url,  successCallback, errorCallback) => {
    const sendUrl = process.env.REACT_APP_SERVER_URL+url;
    const headers =  { 
       Accept:  'video/*, image/*',
    };

    const requestOptions = {
        credentials: "include",
        headers : headers,
    };

    fetch(sendUrl, requestOptions).then(data => successCallback(data)).catch(error => {
        if(errorCallback)
            errorCallback(error);
        else
            console.log(error);
    });
    
    
 }