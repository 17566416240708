import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const Pagination = ({countTotal, countPerPage, selectedPage, ...restProps}) => {
   
    const { t } = useTranslation();

    const paginationItems = useMemo(() => {
      const pages = [];

      let onclickprops = {}

      for (let i = 1; i <= Math.ceil(countTotal / countPerPage); i++){

        if(selectedPage != i)
          onclickprops.onClick = () => {restProps.onChangePage(i)}
        else 
          delete onclickprops.onClick

        pages.push(
          <li className={"page-item"+ ((selectedPage==i)?" disabled":"") } key={"page-item-"+selectedPage} onClick={(selectedPage!=i)?(() => {restProps.onChangePage(i)}):null}><div className="page-link" >{i}</div></li>
        )
      }

      return pages;
    });

    return (
      <>

      <div className="bottom-bar">
     
          <div className="fleft" >
            {t("datatable.info_data", {"first": ((selectedPage-1)*countPerPage)+1, "last": (selectedPage*(countPerPage)), "count":countTotal })}
          </div>

            { (countTotal > countPerPage) && 
            <div className="fright">
              <ul className="pagination">
                <li className={"page-item" +((selectedPage==1)?" disabled":"")} key={"page-prev"}>
                  <div className="page-link" onClick={(selectedPage>1)?() => {restProps.onChangePage(selectedPage-1)}:null} aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                    <span className="sr-only">Previous</span>
                  </div>
                </li>
                {paginationItems}
                <li className={"page-item"+((selectedPage==Math.ceil(countTotal / countPerPage))?" disabled":"")} key={"page-next"}>
                  <div className="page-link" onClick={(selectedPage<Math.ceil(countTotal / countPerPage))?() => {restProps.onChangePage(selectedPage+1)}:null} aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                    <span className="sr-only">Next</span>
                  </div>
                </li>
              </ul>
            </div>
            }
        
          </div>

          <div className="clearer"></div>
      </>
    );
}

export default Pagination;