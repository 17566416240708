import React from "react";
import {dateSQLToLocaleString} from "../../../helpers/date"

const Printed = ({ serial, printedAt, ...restProps }) => {


  return (
    <div className="block-serial-printed" {...restProps} >
        <span className="printedAt">{dateSQLToLocaleString(printedAt)}</span>
        <span className="serial">{serial}</span>
    </div>
  );
}

export default Printed;