import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { fetchServerGET } from "../../../../helpers/fetchServer";

const OneTicket = ({ idTicket, ...restProps }) => {

    const { t } = useTranslation();
    const [data, setData] = useState(false);

    //const [modal, setModal] = useState(false);


    console.log(idTicket);
     /**
     * Load Data Url
     */
      const loadData = () => {
        !data && fetchServerGET("/ticket/one/"+idTicket, (data) => { 
            if(data.success)
                setData(data.data);
        }, () => {
           alert("Error data");
        })
      }

      useEffect(() => {
        loadData();
  
      },[data]);

  return (
    <>
    {!data && <div className="lds-dual-ring"></div>}
    {data && 
    <div className="ticket">
      <div className="row">
        
      </div>
      <div className="row mb-4">
        <div className="col-7">
            <div className="mb-1"><strong>{t('ticket.status')}</strong>: {data.status}</div>
            <div className="mb-1"><strong>{t('common.date')}</strong>: {data.created_at}</div>
            <div className="mb-1"><strong>{t('common.company')}</strong>: {data.company}</div>
        </div>
        <div className="col-5">
            <div className="mb-1"><strong>{t('common.firstname')}</strong>: {data.firstnameContact}</div>
            <div className="mb-1"><strong>{t('common.lastname')}</strong>: {data.lastnameContact}</div>
            <div className="mb-1"><strong>{t('common.email')}</strong>: {data.email}</div>
            {data.phone && <div className="mb-1"><strong>{t('common.phone')}</strong>: {data.phone}</div>}
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <strong>{t('common.comment')}</strong>
          <div className="comment mt-2">
              {data.comment}
          </div>
        </div>
      </div>
      {/*data.file &&
      <div className="row mt-4">
        <div className="col-12">
          <strong>{t('common.photo')}</strong>
          <div className="comment mt-2">
              {data.comment}
          </div>
        </div>
    </div>*/}
    </div>
    }
        
      </>
  );
}

export default OneTicket;