import React from "react";


const Button = ({ children, ...restProps }) => {
  return (
    <button className={restProps.className?restProps.className:"form-btn"} {...restProps}>
      {children}
    </button>
  );
}

export default Button;