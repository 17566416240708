
/**
 * Check if all element required is ok in a form
 * @param {*} formElement 
 */
export const getDatasForm = (formElement) => {
    let errors = [];
    let datas = {};


    formElement.querySelectorAll('input, textarea, select').forEach(child => {

        if(child.getAttribute('data-required')){
            if(!child.value || (child.type === "checkbox" && !child.checked))
                errors.push({name:child.name, error:"error_field_required"});
            else if(child.type === "email" && !isValidEmail(child.value))
                errors.push({name:child.name, error:"error_field_email"});
        }

        if(child.type === "file"){
            datas[child.name] = child.files;

        }else{
            datas[child.name] = child.value;   

        } 
    });

    return {errors:errors, datas:datas, formdatas : new FormData(formElement)};
}

export const isValidEmail = (email) => {
    return String(email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
}

export const isValidSerial = (serial) => {
    return (serial.match(/^[0-9a-zA-Z-]+$/))?true:false;
}

export const replaceForValidSerial = (serial) => {
    return (serial.replace(/[^0-9a-zA-Z-]+/, ''));
}

export const isErrorField  = (errors, field, outTrue, outFalse) => {
    
    if(outFalse===undefined)
        outFalse = false;

    if(outTrue===undefined)
        outTrue = true;

    if(Array.isArray(errors) && errors.length > 0){
      if(errors.filter((error) => error.name == field).length)
            return outTrue;
    }

    return outFalse;
}