import React from 'react';
import {Link, useNavigate} from "react-router-dom";
import { acl } from '../../../configs/acl';

const NavBar = (props) => {

    const history = useNavigate();
    const role = JSON.parse(sessionStorage.getItem('user')).role;

    const  links = [];
    const  adminLinks = [];
    acl.forEach((value, key) => {
        if(value.navbarDashboard && value.allow.find(element=>element==role)){
            if(value.group == "admin")
                adminLinks.push(<Link key={key} className="link" to={key}>{value.title}</Link>);
            else
                links.push(<Link key={key}  className="link" to={key}>{value.title}</Link>);
        }
    })
 
    return   (
        <>
           <div className='navbar'>
                <div className='title'>Navigation</div>
                {links}
                {adminLinks && <div className='title'>Administration</div>}
                {adminLinks}
            </div>
        </>
    )
}

export default NavBar;