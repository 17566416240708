import { useEffect, useState } from "react";
import { fetchServerPOST} from "../../../helpers/fetchServer"
import TopBar from "./TopBar/TopBar";
import Pagination from "./Pagination/Pagination";

const DataTable = ({ thead, tbody, page, limit, url, searchbar, changeRows, pagination, ...restProps }) => {
  
  const [params, setParams] = useState({search: false, page:page?page:1, limit:limit?limit:10})
  const [datas, setDatas] = useState(false);


    /**
     * Load Data Url
     */
    const loadData = () => {
      !datas && fetchServerPOST(url, params, (data) => {
        
        setDatas(data);
          
      }, () => {
          //setCaptcha({img:"Error Captcha", token:""});
      })
    }

    /**
     * Set Search Bar
     */
    const onChangeSearch = (value) => {
      setParams({search: value, page:1, limit:params.limit});
      setDatas(false);
    }

    /**
     * Set Page
     */
     const onChangePage = (value) => {
      setParams({search: params.search, page:value, limit:params.limit});
      setDatas(false)
    }

     /**
     * Set Page
     */
      const onChangeRows = (value) => {
        setParams({search: params.search, page:params.page, limit:value});
        setDatas(false)
      }

    useEffect(() => {
      loadData();

    },[datas]);


    return (
      <>
        {( searchbar || changeRows ) && <TopBar searchbar={searchbar} changeRows={changeRows} onChangeSearch={onChangeSearch} onChangeRows={onChangeRows} key="datatable-topbar" />}
        <table {...restProps}>
            {thead && 
              <thead>
                <tr>
                  {thead.map((data, index)=> <th key={index} width={data.width?data.width:"auto"}>{data.title}</th>)}
                </tr>
              </thead>
            }

            {thead && 
              <tbody>
                {datas.data && datas.data.map( (row,indexD) => <tr>{thead.map((data, index)=> 
                  <td key={index+"-"+indexD}>
                    {(data.items&&data.keyItems&&row[data.keyItems])?data.items(row[data.keyItems]):(row[data.key])?row[data.key]:""}
                  </td>)}
                </tr>)}
                {!datas.data && <tr className="tr-loader"><td className="td-loader" align="center" colSpan={thead.length}><div className="lds-dual-ring"></div></td></tr>}
              </tbody>
            }
        </table>
        {pagination && <Pagination countTotal={datas?datas.count:0} countPerPage={params.limit} selectedPage={params.page}  onChangePage={onChangePage} key="datatable-pagination"/>}
       
      </>
    );
}

export default DataTable;