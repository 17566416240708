import React, { useRef } from "react";
import { useState } from "react";


const Title = ({ children, ...restProps }) => {

  const [file, setFile] = useState(null);

  const handleChange = (event) => {
    setFile({src:URL.createObjectURL(event.target.files[0])})
  }

  return (
    <h4 className={restProps.className?restProps.className:""} {...restProps} >{children}</h4>
  );
}

export default Title;