import React, { useEffect, useState, useRef} from 'react';
import { fetchServerGET } from '../../../helpers/fetchServer';



const Captcha = (props) => {
    
    const [captcha, setCaptcha] = useState({img:"Error Captcha", token:""});
    const captchaReload = useRef(0);

    function reloadCaptcha (){
        if(captcha.token )
            setCaptcha({img:"Error Captcha", token:""});

        captchaReload.current = 3600;
        fetchServerGET("/captcha",(data) => {

            setCaptcha(data.data);
            
        }, () => {
            setCaptcha({img:"Error Captcha", token:""});
        })
    }


    useEffect(() => {
        if(captcha.token === ""){

            setInterval(()=>{
                captchaReload.current = captchaReload.current-1;

                if(captchaReload.current <= 0)
                    reloadCaptcha();
                

            }, 1000);
        }

       
        
    },[])
 
    return (  
        <div className={props.className?props.className:"form-btn"}>
            
            {captcha.token && <input type="hidden" name="token" value={captcha.token} />}
           <input type="text" className=""  data-required name="captcha"   />
           {!captcha.token &&
                <div className="spinner-border text-info ml-2" role="status">
                    <span className="sr-only">Loading...</span>
                </div>}
           {captcha.token &&   <img src={`data:image/svg+xml;base64,${captcha.img}`} alt="captcha"/>}
           <div className='mb-3 reloadCaptcha'><span onClick={reloadCaptcha}>Reload Captcha</span></div>
        </div>
    )
};

export default Captcha;