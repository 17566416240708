import React from "react";


const Title1 = ({ children, ...restProps }) => {
  return (
    <h1 className={restProps.className?restProps.className:"form-title"} {...restProps}>
      {children}
    </h1>
  );
}

export default Title1;