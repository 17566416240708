import { useState } from "react";
import {useNavigate} from "react-router-dom";
import { fetchServerGET, fetchServerPOST} from "../helpers/fetchServer"

const LoginPage = (props) => {
    const history = useNavigate();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");

    function handleSubmit(event) {
        event.preventDefault();

        fetchServerPOST("/user/login", {username: username, password: password}, (data)=>{

          if(data.error){
            setError(data.error);
            return;
          }

          if(data.data.user){
         
            sessionStorage.setItem("user",JSON.stringify(data.data.user));
            props.role(data.data.user.role);

            history("/");
          }
        });
    }

   


     

      return (
        <>
          <section className="login-block">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-12">
                  <form className="md-float-material form-material" onSubmit={handleSubmit}>
                    <div className="auth-box card">
                      <div className="card-block">
                        <div className="row m-b-20">
                          <div className="col-md-12">
                            <h3 className="text-center txt-primary">Connexion</h3>
                          </div>
                        </div>
                        {error && <p className="error">{error}</p>}
                        <div className="form-group form-primary">
                          <input type="text" name="user-name" className="form-control"  required="" placeholder="Username" value={username} onChange={({ target }) => setUsername(target.value)} />
                            <span className="form-bar"></span>
                        </div>
                        <div className="form-group form-primary">
                          <input type="password" name="password" className="form-control" required="" placeholder="Password"  value={password} onChange={({ target }) => setPassword(target.value)} />
                            <span className="form-bar"></span>
                        </div>
                        <div className="row m-t-30">
                          <div className="col-md-12">
                            <button type="submit" className="btn btn-primary btn-md btn-block waves-effect text-center m-b-20">LOGIN</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </>
      );
}

export default LoginPage;