import React from 'react';
import UserMenu from './UserMenu';


import Logo from '../../../assets/img/logo.png';


const Header = (props) => {

   

    return (
        <>
            <nav className='header'>
                <div className='logo'>
                    <img src={Logo} alt="Logo Sav" />
                </div>
                <div className='nav-container'>
                    <UserMenu />
                </div>
            </nav>
        </>
    );
}

export default Header;