import React from "react";


const Label = ({ children, ...restProps }) => {
  return (
    <label className={restProps.className?restProps.className:"form-textarea"} {...restProps}>
      {children}
    </label>
  );
}

export default Label;