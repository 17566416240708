import React from 'react';
import {useNavigate} from "react-router-dom";

const Loader = (props) => {
 
    return (  
        <>
            <div className="bg-loader">
                <div className="tr-loader">
                    <div className="td-loader" align="left">
                        <div className="lds-dual-ring"></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Loader;