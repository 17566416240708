import { useRef } from "react";


const TopBar = (props) => {

    const timer = useRef(null);

    const onChangeSearchFunc = (event) => {
      if(props.onChangeSearch){
        clearTimeout(timer.current)
        timer.current = setTimeout(() => {props.onChangeSearch(event.target.value)}, 500);
      }
    }

    const onChangeRowsFunc = (event) => {
      if(props.onChangeRows){
        props.onChangeRows(event.target.value);
      }
    }

    return (
      <>
        <div className="top-bar">
          {props.changeRows &&
            <div className="fleft" onChange={onChangeRowsFunc}>
              
              <select>
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select> 
                &nbsp;rows
            </div>
          }
          {props.searchbar &&
            <div className="fright">
              <input className="input-search" placeholder="Search..." onChange={onChangeSearchFunc} />
            </div>
          }
          <div className="clearer"></div>
        </div>
      </>
    );
}

export default TopBar;